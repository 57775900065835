<template>
  <v-dialog v-model="open" persistent width="700">
    <v-card>
      <v-card-title class="header">
        <h3>管理一番賞圖文( {{ item.name }} )
        </h3>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            dense
            hide-details
            @click="saveAll"
            class=""
            :disabled="!hasNewContent"
          >
            全部儲存
          </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <div class="mb-4 d-flex justify-space-between align-center">
            <h3>長型預覽圖</h3>
            <div>
              <v-btn
                v-if="previewImageUrlFile"
                @click="previewImageUrlFile = null"
                color="error"
              >
                移除
              </v-btn>
              <v-btn
              v-else-if="item.previewImageUrl"
                @click="remove('previewImageUrl')"
                color="error"
              >
                移除
              </v-btn>
              <v-btn
              v-else disabled
              >
                移除
              </v-btn>
            </div>
          </div>
          <div class="preview-image mb-4">
            <LocalImagePreview v-if="previewImageUrlFile" :imageFile="previewImageUrlFile" class="preview-image"
            />
            <img v-else-if="item.previewImageUrl" :src="item.previewImageUrl" />
            <div v-else class="preview-image__placeholder"></div>
          </div>
          <div>
            <div class="d-flex">
              <v-file-input
                v-model="previewImageUrlFile"
                accept="image/png, image/jpeg, image/webp"
                label="更新預覽圖"
                outlined
                dense
                hide-details
                @change="
                  getLocalImageFile(previewImageUrlFile, 'previewImageUrl')
                "
                class="mb-4"
                @click:clear="previewImageUrl = ''"
              ></v-file-input>
            </div>
          </div>
        </div>
        <v-divider class="my-6"></v-divider>
        <div>
          <div class="mb-4 d-flex justify-space-between align-center">
            <h3>方形預覽圖</h3>
            <div>
              <v-btn
                v-if="gridPreviewImageUrlFile"
                @click="gridPreviewImageUrlFile = null"
                color="error"
              >
                移除
              </v-btn>
              <v-btn
              v-else-if="item.gridPreviewImageUrl"
                @click="remove('gridPreviewImageUrl')"
                color="error"
              >
                移除
              </v-btn>
              <v-btn
              v-else disabled
              >
                移除
              </v-btn>
            </div>
          </div>
          <div class="preview-image preview-image--grid mb-4">
            <LocalImagePreview v-if="gridPreviewImageUrlFile" :imageFile="gridPreviewImageUrlFile" class="preview-image preview-image--grid"/>
            <img v-else-if="item.gridPreviewImageUrl" :src="item.gridPreviewImageUrl" />
            <div v-else class="preview-image__placeholder"></div>
          </div>
          <div>
            <div class="d-flex">
              <v-file-input
                v-model="gridPreviewImageUrlFile"
                accept="image/png, image/jpeg, image/webp"
                label="更新預覽圖"
                outlined
                dense
                hide-details
                @change="
                  getLocalImageFile(gridPreviewImageUrlFile, 'gridPreviewImageUrl')
                "
                class="mb-4"
                @click:clear="gridPreviewImageUrl = ''"
              ></v-file-input>
            </div>
          </div>
        </div>
        <v-divider class="my-6"></v-divider>
        <div>
          <div class="d-flex justify-space-between mb-4">
            <h3>原內容圖片</h3>
            <v-btn
              @click="remove('contentImageUrls')"
              color="error"
              :disabled="item.contentImageUrls?.length <= 0"
            >
              全部移除
            </v-btn>
          </div>
          <div v-if="item.contentImageUrls?.length > 0" class="images-grid mb-4">
            <div v-for="(imageUrl, index) in item.contentImageUrls" class="images-grid__box">
              <img :src="imageUrl">
              </img>
              <v-btn color="error" fab small @click="removeFromUrls(index)">
                <v-icon > mdi-close </v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else class="mb-4">無</div>
          <h3 class="mb-4">新增內容圖片</h3>
          <div  v-if="contentImageUrls?.length > 0" class="images-grid mb-4">
            <div v-for="(url, index) in contentImageUrls" class="images-grid__box">
              <img :src="url" />
              <v-btn color="error" fab small @click="()=> {
                contentImageUrls.splice(index, 1);
                contentImageUrlsFile.splice(index, 1);
                }">
                <v-icon > mdi-close </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex">
            <v-file-input
              v-model="contentImageUrlsFile"
              accept="image/png, image/jpeg, image/webp"
              label="新增內容圖"
              outlined
              dense
              hide-details
              multiple
              @change="getLocalImageFiles"
              class="mb-4"
            ></v-file-input>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!hasNewContent" text class="bt-text-large" color="primary" @click="open = false">
          關閉
        </v-btn>
        <v-btn v-else text class="bt-text-large" color="primary" @click="saveAll($event, true)">
          儲存並關閉
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import LocalImagePreview from "@/components/share/LocalImagePreview.vue";

export default {
  name: "DialogIchibanDisplay",
  props: ["value", "item", "branches"],
  components: { LocalImagePreview },
  data: () => ({
    previewImageUrl: null,
    previewImageUrlFile: null,
    gridPreviewImageUrl: null,
    gridPreviewImageUrlFile: null,
    contentImageUrls: [],
    contentImageUrlsFile: [],
  }),
  created() {
    if (this.item) {
      this.ichiban = _.cloneDeep(this.item);
    }
  },
  computed: {
    isNew() {
      return !this.item || !this.item._id;
    },
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    hasNewContent(){
      return !!this.previewImageUrlFile || !!this.gridPreviewImageUrlFile || !!this.contentImageUrlsFile.length
    }
  },
  methods: {
    async saveAll(_event, closeAfterSave){
      try {
        const files = [];

        if (this.previewImageUrlFile) {
          files.push({ file: this.previewImageUrlFile, key: 'previewImageUrl' });
        }
        if (this.gridPreviewImageUrlFile) {
          files.push({ file: this.gridPreviewImageUrlFile, key: 'gridPreviewImageUrl' });
        }
        if (this.contentImageUrlsFile.length > 0) {
          this.contentImageUrlsFile.forEach(file => {
            files.push({ file, key: 'contentImageUrls' });
        });
      }

      if (files.length === 0) {
        this.open = false;
        return;
      }


      this.$vloading.show();
      const fileList = files.map(f => f.file);
      const imageUrls = await this.getImageUrls(fileList);
      // 映射返回的 URL 到正確的鍵
      let urlIndex = 0;
      const updates = {};
      files.forEach(({ key }) => {
        if (key === 'contentImageUrls') {
          if (!updates[key]) {
            updates[key] = [...this.item.contentImageUrls];
          }
          updates[key].push(imageUrls[urlIndex]);
        } else {
          updates[key] = imageUrls[urlIndex];
        }
        urlIndex++;
      });

      await this.axios.put(`/ichiban/${this.item._id}`, updates);

      this.$emit("load");
      this.$toast.success("儲存成功");

      if(closeAfterSave){
        this.open = false;
      }
    } catch(e) {
        console.log(e);
        this.$toast.error("儲存失敗");

        if(e.response){
          const info = JSON.stringify(e.response)
          this.$toast.error(info);
        } else {
          this.$toast.error(e);
        }
      }finally{
        this.clearLocalFiles();
        this.$vloading.hide();
      }
    },
    clearLocalFiles(){
      this.previewImageUrl = null;
      this.previewImageUrlFile = null;
      this.gridPreviewImageUrl = null;
      this.gridPreviewImageUrlFile = null;
      this.contentImageUrls = [];
      this.contentImageUrlsFile = [];
    },
    async getImageUrls(files) {
        let formData = new FormData();
        files.forEach((file) => {
          formData.append(`file`, file);
        });

        const { data } = await this.axios.post(
          `/image/upload-multiple-image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // 設置 Content-Type 為 multipart/form-data
            },
          }
        );
        return data.imageUrls
    },
    async remove(key) {
      const confirm = await this.$dialog.confirm({
        text: `確定要移除圖片？`,
      });

      if (!confirm) return;

      this.$vloading.show();
      try {
        const emptyPayload = key === "contentImageUrls" ? [] : "";
        await this.axios.put(`/ichiban/${this.item._id}`, {
          [key]: emptyPayload,
        });

        this.$emit("load");

        setTimeout(() => {
          this.$toast.success("移除成功");
        }, 1000)
      } catch (error) {
        this.$toast.error("移除失敗");
      }finally{
        this.$vloading.hide();
      }
    },
    async removeFromUrls(targetIndex){
      // const confirm = await this.$dialog.confirm({
      //   text: `確定要移除圖片？`,
      // });
      const confirm = await window.confirm('確定要移除圖片？');

      if (!confirm) return;

      this.$vloading.show();
      try {
        const updatedUrls = this.item.contentImageUrls.filter((_url, index) => index !== targetIndex)
        await this.axios.put(`/ichiban/${this.item._id}`, {
          contentImageUrls: updatedUrls,
        });

        this.$emit("load");

        setTimeout(() => {
          this.$toast.success("移除成功");
        }, 1000)
      } catch (error) {
        this.$toast.error("移除失敗");
      } finally{
        this.$vloading.hide();
      }
    },
    getLocalImageFile(file, key) {
      if (!file) return;
      this[key] = URL.createObjectURL(file);
    },
    getLocalImageFiles(event) {
      if (!event) return;
      this.contentImages = event;
      this.contentImageUrls = event.map((file) => {
        const objectURL = URL.createObjectURL(file);
        return objectURL;
      });
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.header{
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: white;
}

.preview-image {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 300px;
  background-color: #e4e4e4;

  &--grid{
    width: 300px;
    height: 300px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__placeholder {
    &::after {
      content: "無";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  &__box{
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 300px;
    // height: 100%;

    button{
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
